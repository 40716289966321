/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from "../images/favicon.png"

function SEO({ lang, meta, metaTitle, metaDescription, shareImage, author, splash }) {

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            bodyAttributes={{
                class: splash ? 'splash' : ''
            }}
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: metaTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: shareImage
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: author
                },
                {
                    name: `twitter:title`,
                    content: metaTitle
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                }
            ].concat(meta)}
        >
            <link rel="icon" type="image/png" href={favicon} />
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    metaTitle: ``,
    metaDescription: ``,
    author: ``,
    shareImage: ``
}

SEO.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    author: PropTypes.string,
    shareImage: PropTypes.string,
}

export default SEO